import axios from 'axios';
//test
axios.defaults.baseURL = 'https://app.gurugfood.com:8444/remittance-license/';
//live
// axios.defaults.baseURL = 'https://laxmipay.ausnepit.me:8444/remittance-license/';

if (!!localStorage.getItem('Token')) {
  axios.defaults.headers.common['Authorization'] = localStorage.getItem('Token');
}
export const setLocal = Token => {
  axios.defaults.headers.common['Authorization'] = Token;
};

const Login = data => {
  return axios.post(`login`, data);
};
const AddLogo = formData => {
  return axios.post(`add-logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
const editLicense = data => {
  return axios.put(`/license/edit-color/${data.id}`, data);
};
const AddKey = data => {
  return axios.post(`license-add`, data);
};
const LicenseList = data => {
  return axios.get(`licensed-lists?pageNo=${data.pageNo}&pageSize=${data.pageSize}`);
};

const getAgentById = data => {
  return axios.get(`get-agent-by-id/${data}`);
};
const LicenseActive = data => {
  console.log('apidata', data);
  return axios.get(
    `activate-license?id=${data.id}&confirmationText=Ok&expiryDate=${data.expiryDate}`
  );
};
const LicenseDactive = data => {
  console.log('apidata', data);

  return axios.get(`deactivate-license?id=${data.id}&confirmationText=Ok`);
};
const agentDeactive = data => {
  console.log('apidata', data);

  return axios.get(`/deactivate-agent/${data.id}`);
};
const agentActive = data => {
  console.log('apidata', data);

  return axios.get(`/activate-agent/${data.id}`);
};

const AdminProfileapi = data => {
  console.log('adminprofileapi', data);
  return axios.get(`get-details/${data}`);
};

const AgentDataapi = data => {
  console.log('adminprofileapi', data);
  return axios.get(`get-agent-by-id/${data}`);
};
const getDashboardData = data => {
  return axios.get(`get-dashboard-data?agentId=${data.agentId}`);
};
const getDashboard = () => {
  return axios.get(`get-dashboard-data`);
};
const getBankData = data => {
  return axios.get(
    `agent/get-associated-bank-list?agentCode=${data.agentCode}&page=${data.pageNo}&pageSize=${data.pageSize}`
  );
};
const getRemittanceBranchData = data => {
  return axios.get(
    `agent/get-remittance-branch-list?agentCode=${data.agentCode}&page=${data.pageNo}&pageSize=${data.pageSize}`
  );
};
const getCommissionData = data => {
  return axios.get(
    `agent/get-commission-list?agentCode=${data.agentCode}&page=${data.pageNo}&pageSize=${data.pageSize}`
  );
};
const getExchnageRateData = data => {
  return axios.get(
    `agent/get-exchange-rate-list?agentCode=${data.agentCode}&page=${data.pageNo}&pageSize=${data.pageSize}`
  );
};
const getPaymentData = data => {
  return axios.get(
    `agent/get-payment-list?agentCode=${data.agentCode}&page=${data.pageNo}&pageSize=${data.pageSize}&status=${data.status}`
  );
};
const getPaymentDetail = data => {
  return axios.get(
    `/agent/get-payment-by-id?agentCode=${data.agentCode}&paymentId=${data.paymentId}`
  );
};

export {
  Login,
  AddKey,
  LicenseList,
  LicenseActive,
  LicenseDactive,
  agentDeactive,
  agentActive,
  AdminProfileapi,
  AddLogo,
  AgentDataapi,
  getDashboardData,
  getDashboard,
  getBankData,
  getRemittanceBranchData,
  getCommissionData,
  getExchnageRateData,
  getPaymentData,
  getPaymentDetail,
  getAgentById,
  editLicense,
};

export { axios };
